










import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { SearchViewModel } from '../../viewmodels/search-viewmodel'

@Observer
@Component({
  components: {
    'user-card': () => import('@/modules/search/components/user-card.vue'),
  },
})
export default class Users extends Vue {
  @Inject() vm!: SearchViewModel
}
